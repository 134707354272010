@import "../../index.scss";

.resume {
  @include section-layout(url("../images/profile/bg3.png"));

  &__heading {
    margin-top: 1rem;
  }

  &__card {
    display: flex;
    width: 70%;
    gap: 2rem;
    background-color: rgba(255, 255, 255, 0.588);
    padding: 1.5rem 1.5rem;
    border-radius: 3rem;
    box-shadow: 0 0 20px -2px var(--dark-blue);

    &--section-bullets {
      display: flex;
      box-shadow: 15px 0 9px -15px #1f2235;
      margin-top: 3rem;
      width: 35%;

      @media (max-width: 600px) {
        width: 40%;
      }
    }

    &--section-details {
      font-size: 1.3rem;
      font-weight: 500;
      margin-top: 3rem;
      margin-bottom: 3rem;
      width: 100%;

      .details {
        display: none;
      }

      .details_active {
        display: block;
      }

      @media (max-width: 600px) {
        width: 45%;
      }
    }

    @media (max-width: 1500px) {
      width: 80%;
    }

    @media (max-width: 1000px) {
      width: 90%;
    }
  }

  @media (max-width: 600px) {
    border-top: 0.5px solid #1f223516;
  }

  @media (max-width: 500px) {
    display: none;
  }
}

.logo {
  background-color: var(--dark-blue);
  padding: 5rem 1.5rem 2rem 1rem;
  border-radius: 3rem;
  height: 21rem;

  li {
    margin-bottom: 3rem;
    width: 2rem;

    img {
      @media (max-width: 700px) {
        width: 1.3rem;
      }
    }
  }

  @media (max-width: 1500px) {
    padding: 5rem 0rem 1rem 1rem;
  }

  @media (max-width: 800px) {
    height: 18rem;
  }

  @media (max-width: 700px) {
    height: 18rem;
    padding: 3rem 0rem 1rem 1rem;
  }

  @media (max-width: 500px) {
    height: 15rem;
    padding: 2.7rem 0.5rem;
  }
}

.labels {
  color: var(--dark-blue);
  font-size: clamp-calc(1900px, 300px, 16px, 10px);
  font-weight: 500;
  padding: 4rem 1rem 3rem 0;
  cursor: pointer;

  li {
    padding: 0.8rem 1rem 0.8rem 2rem;
    margin-bottom: 0.9rem;

    @media (max-width: 1100px) {
      padding: 0.8rem;
    }
  }
}

.active {
  background-color: var(--dark-blue);
  color: white;
  border-top-right-radius: 3rem;
  border-bottom-right-radius: 3rem;
}

.detail {
  margin-bottom: 1.5rem;

  &__main-heading {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1rem;

    &--dot {
      height: 0.2rem;
      width: 0.2rem;
      border-radius: 50%;
      background-color: var(--dark-orange);
    }

    &--text {
      color: var(--dark-orange);
      font-weight: 700;
      font-size: clamp-calc(1900px, 300px, 22px, 15px);
    }

    &--date {
      background-color: #ff5823;
      padding: 4px 1rem;
      font-size: 0.8rem;
      border-radius: 1rem;
      color: var(--white);
    }
  }

  &__sub-heading {
    font-size: clamp-calc(1900px, 300px, 19px, 12px);
  }
}
