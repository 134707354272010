@import "./../../index.scss";

.projects {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  border-top: 0.5px solid #1f223516;

  &__heading {
    margin-top: 1rem;
  }

  @media (max-width: 400px) {
    gap: 2rem;
  }
}

.project {
  display: flex;
  gap: 1rem;
  margin: 2rem 0 2rem 2rem;

  &:last-child {
    justify-content: center;
  }

  &__description {
    width: 50%;

    &:last-child {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    &--title {
      font-size: clamp-calc(1900px, 300px, 35px, 15px);
    }

    &--text {
      margin-top: 2rem;
      font-size: clamp-calc(1900px, 300px, 20px, 15px);

      @media (max-width: 600px) {
        display: none;
      }
    }

    &--tech {
      margin-top: 2rem;
      font-size: clamp-calc(1900px, 300px, 24px, 16px);

      @media (max-width: 600px) {
        margin: 2rem;
      }
    }

    &--buttons {
      margin-top: 2rem;
      display: flex;
      gap: 2rem;

      @media (max-width: 800px) {
        gap: 0.7rem;
        margin: 0;
      }
    }

    @media (max-width: 800px) {
      width: 100%;
      padding: 1rem;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    @media (max-width: 400px) {
      text-align: center;
      padding: 0;
    }
  }

  &__img {
    width: 50%;

    @media (max-width: 800px) {
      width: 80%;
    }

    @media (max-width: 400px) {
      width: 100%;
    }
  }

  @media (max-width: 800px) {
    flex-direction: column-reverse;
    align-items: center;
    margin: 0 0 2rem 0;
    gap: 4rem;
    width: 100%;
  }

  @media (max-width: 400px) {
    gap: 1rem;
    width: 80%;
  }
}

.tech {
  font-weight: 600;
  padding-right: 1rem;

  @media (max-width: 600px) {
    font-weight: 500;
    padding-right: 0.5rem;
  }
}
