@import "../../index.scss";

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 1rem 2.5rem 1rem;
  color: var(--dark-blue);

  &__logo {
    width: 6rem;
    border-radius: 50%;

    @media (max-width: 800px) {
      width: 5rem;
    }

    @media (max-width: 500px) {
      width: 6rem;
    }

    @media (max-width: 400px) {
      width: 4rem;
    }
  }

  &__details {
    display: flex;
    align-items: center;
    gap: 2rem;
    font-size: clamp-calc(1900px, 300px, 24px, 16px);
    font-weight: 600;
    font-family: "Poppins";

    a:hover {
      color: var(--dark-orange);
      cursor: pointer;
      transform: scale3d(1.05, 1.05, 1.05);
    }

    @media (max-width: 700px) {
      display: none;
    }
  }

  &__shortcut {
    cursor: pointer;

    @media (min-width: 700px) {
      display: none;
    }

    @media (max-width: 500px) {
      width: 1.5rem;
      height: 1.5rem;
      margin-right: 0.5rem;
    }
  }

  &__shortnav {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    gap: 2.5rem;
    font-size: 1.5rem;
    background-color: #f8e1d8f5;
    right: 0%;
    top: 0%;
    width: 100%;
    height: 50rem;
    z-index: 100;
    padding: 5rem 5rem;
    font-weight: 600;
    font-family: "Poppins";
    padding-top: 8rem;

    a:hover {
      color: var(--dark-orange);
      transform: scale3d(1.05, 1.05, 1.05);
    }

    @media (max-width: 400px) {
      font-size: 1.3rem;
    }
  }

  .close-btn {
    position: absolute;
    top: 4%;
    right: 5%;
  }
}
