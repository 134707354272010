@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Arima:wght@100;200;300;400;500;600;700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  &:after,
  &:before {
    box-sizing: border-box;
  }
}

html {
  scroll-behavior: smooth;
}

:root {
  --beige: #f8eedd;
  --beige-bg: #faf6ed;
  --pink: #f0cfc3;
  --light-pink: #f8e1d8;
  --logo-pink: #fa9583;
  --green: #b2d8ad;
  --yellow: #fdd186;
  --gray: #706f6f;
  --light-blue: #04c3ff;
  --dark-blue: #1f2235;
  --dark-orange: #f77763;
  --white: #fff;
  --black: #333;
}

@mixin section-layout($bg-url) {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 2rem;

  background-image: $bg-url;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

html,
body {
  overflow-x: hidden;
}

body {
  font-family: "Arima", cursive;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button,
input,
textarea,
select {
  font: inherit;
  cursor: pointer;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}

a {
  text-decoration: none;
  color: inherit;
}

ul {
  list-style: none;
}

::-webkit-scrollbar {
  width: 6px !important;
  height: 6px !important;
}

body ::-webkit-scrollbar-thumb {
  background-color: hsla(0, 0%, 100%, 0.16);
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
}

::-webkit-scrollbar-track {
  background: hsla(0, 0%, 100%, 0.1);
}

.btn {
  border-radius: 3rem;
  padding: 1rem 0;
  width: 11rem;
  cursor: pointer;
  transition: 0.2s;
  font-weight: 500;
  font-family: "Poppins";

  &:active {
    transform: translateY(2px);
  }

  &:hover {
    background-color: var(--light-pink);
  }

  @media (max-width: 1000px) {
    width: 9rem;
    font-size: 0.9rem;
  }

  @media (max-width: 600px) {
    width: 8rem;
    font-size: 0.8rem;
  }
}

.primary-btn {
  color: var(--white);
  border: 2px solid linen;
  background-color: var(--dark-blue);

  &:hover {
    color: var(--dark-blue);
    border: 2px solid var(--dark-orange);
  }
}

.highlighted-btn {
  color: var(--white);
  background-color: var(--dark-orange);

  &:hover {
    color: var(--dark-blue);
    border: 2px solid var(--dark-orange);
  }
}

@function clamp-calc(
  $min-width,
  $max-width,
  $size-at-min-width,
  $size-at-max-width
) {
  $slope: ($size-at-max-width - $size-at-min-width) / ($max-width - $min-width);
  $y-axis-intersection: -1 * $min-width * $slope + $size-at-min-width;
  $return-value: clamp(
    #{$size-at-min-width},
    #{$y-axis-intersection} + #{$slope} * 100vw,
    #{$size-at-max-width}
  );
  @return $return-value;
}
