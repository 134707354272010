.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.2rem;
  font-family: "Poppins";

  &__scroll {
    margin-top: 2rem;
    text-align: center;

    @media (max-width: 800px) {
      width: 2.5rem;
      height: 2.5rem;
    }
  }

  &__socials {
    display: flex;
    gap: 1rem;
    margin-top: 3rem;

    a:hover {
      transform: scale(1.5);
    }

    @media (max-width: 800px) {
      margin-top: 2rem;
    }
  }

  p {
    margin-top: 3rem;
    margin-bottom: 3rem;
    padding-top: 2rem;
    border-top: 0.1rem solid rgba(0, 0, 0, 0.12);

    @media (max-width: 800px) {
      margin-top: 2rem;
      margin-bottom: 2rem;
    }
  }

  @media (max-width: 800px) {
    font-size: 1rem;
  }
}
