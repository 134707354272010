@import "./../../index.scss";

.heading {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 3rem;
  font-family: "Poppins";
  color: var(--dark-blue);

  &__title {
    font-weight: 600;
    font-size: clamp-calc(1900px, 300px, 44px, 27px);
  }

  &__subtitle {
    letter-spacing: 3px;
    font-size: 0.8rem;
    margin: 0.5rem 0 1rem 0;

    @media (max-width: 900px) {
      font-size: 0.7rem;
    }
  }

  &__separator {
    display: flex;
    align-items: center;
    position: relative;
    width: 12rem;
    margin-top: 1rem;

    &--line {
      width: 100%;
      height: 2px;
      background-color: var(--dark-blue);
    }

    &--knot {
      height: 1rem;
      width: 100%;
      position: absolute;
      bottom: -0.5rem;
      display: flex;
      justify-content: center;

      &--inner {
        width: 2.1rem;
        border-radius: 0.6rem;
        background-color: var(--dark-orange);
      }
    }

    @media (max-width: 700px) {
      width: 10rem;
      margin-top: 0.3rem;
    }
  }
}
