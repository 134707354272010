@import "../../index.scss";

.about {
  @include section-layout(url("../images/profile/bg1.png"));

  &__container {
    display: flex;
    align-items: center;
    width: 70%;
    margin-top: 1rem;
    box-shadow: 0 0 20px -2px var(--dark-blue);
    background-color: rgba(255, 255, 255, 0.588);
    border-radius: 3rem;

    &--img {
      width: 150rem;
      padding: 1rem;
      display: flex;
      flex-direction: column;
      align-items: center;

      img {
        width: 100%;
        border-radius: 2rem;
      }

      @media (max-width: 800px) {
        width: 70%;
        padding: 2rem 0 0 0;
      }
    }

    &--details {
      padding: 1.8rem;
      font-size: 1.2rem;
      line-height: 2.5rem;

      p:not(:first-child) {
        margin-top: 1rem;
      }

      @media (max-width: 1200px) {
        line-height: 2.2rem;
        padding: 1.5rem;
        font-size: 1rem;
      }

      @media (max-width: 1100px) {
        padding: 1rem;
        font-size: 1rem;
        line-height: 2rem;
      }

      @media (max-width: 800px) {
        padding: 0.5rem 3rem 2rem 3rem;
      }

      p:nth-child(2) {
        @media (max-width: 900px) {
          display: none;
        }
      }


      @media (max-width: 500px) {
        text-align: center;
        padding: 1.8rem;
      }
    }

    &--highlight {
      display: flex;
      align-items: center;
      gap: 1rem;

      &--dot {
        height: 10px;
        width: 10px;
        border-radius: 50%;
        background-color: var(--dark-orange);
      }

      @media (max-width: 800px) {
        display: none;
      }
    }

    &--buttons {
      font-size: 1rem;
      margin-top: 2.5rem;
      display: flex;
      gap: 2rem;

      @media (max-width: 800px) {
        display: none;
      }
    }

    @media (max-width: 1500px) {
      width: 80%;
    }

    @media (max-width: 1000px) {
      width: 90%;
    }

    @media (max-width: 800px) {
      flex-direction: column;
      margin-top: 0rem;
    }
  }

  @media (max-width: 600px) {
    border-top: 0.5px solid #1f223516;
    padding-top: 1rem;
  }
}
