@import "../../index.scss";

.bottom {
  bottom: 0;

  & img {
    transform: rotateY(180deg);
  }
}

.contact {
  @include section-layout(url("../images/profile/bg1.png"));
  border-top: 0.5px solid #1f223516;

  &__heading {
    margin-top: 1rem;
  }

  &__card {
    background-color: rgba(255, 255, 255, 0.588);
    padding: 2rem 2rem;
    border-radius: 3rem;
    box-shadow: 0 0 20px -2px var(--dark-blue);
    width: 70%;
    font-size: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      width: 90%;
      text-align: center;
    }

    &--form {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      margin-top: 2rem;

      input {
        margin-bottom: 2rem;
        padding: 0.2rem 0 0.2rem 1rem;
        width: 50%;
        border-radius: 1rem;
        border: solid 0.1rem rgba(0, 0, 0, 0.239);

        @media (max-width: 1000px) {
          width: 70%;
        }

        @media (max-width: 600px) {
          width: 80%;
          margin-bottom: 1.5rem;
        }
      }

      button {
        font-size: 1.2rem;

        span {
          margin-left: 1rem;
        }

        @media (max-width: 800px) {
          font-size: 1rem;
        }

        @media (max-width: 600px) {
          font-size: 0.8rem;
        }
      }
    }

    @media (max-width: 1500px) {
      width: 80%;
      font-size: 1.3rem;
    }

    @media (max-width: 1000px) {
      width: 90%;
    }

    @media (max-width: 700px) {
      font-size: 1.2rem;
    }

    @media (max-width: 600px) {
      font-size: 1rem;
      padding: 1.5rem 1.5rem;
    }
  }
}
