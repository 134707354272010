.loading {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100vh;
  width: 100%;
  z-index: 100;
  background-color: var(--light-pink);
  display: grid;
  place-items: center;
  font-family: "Poppins", sans-serif;

  &__box {
    position: relative;
    width: 31rem;
    height: 12.5rem;
    border: 5px solid #000;
    border-top: 5px solid rgba(0, 0, 0, 0.5);
    border-bottom: 5px solid rgba(0, 0, 0, 0.5);

    @media (max-width: 900px) {
      width: 27rem;
    }

    @media (max-width: 600px) {
      width: 20rem;
    }
  }

  &__bar {
    width: 90%;
    height: 1rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%);
    background: rgba(0, 0, 0, 0.2);
    border-radius: 2px;
    &--inner {
      height: 100%;
      border-radius: 2px;
      background: rgba(0, 0, 0, 0.8);
    }
  }

  &__text {
    position: relative;
    color: rgba(0, 0, 0, 0.9);
    padding: 1rem;
    font-size: 1.5rem;
    font-weight: 700;
    text-shadow: 3px 3px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 500px) {
      font-size: 1.3rem;
    }

    @media (max-width: 400px) {
      font-size: 1rem;
    }

    &--dot {
      animation: pulse 1s infinite;
      @keyframes pulse {
        from {
          opacity: 0;
        }
        to {
          opacity: 1;
        }
      }
    }

    &--border {
      width: 85%;
      height: 2px;
      position: absolute;
      bottom: 0;
      left: 50%;
      background: rgba(0, 0, 0, 0.4);
      transform: translateX(-50%);
    }
  }

  &__counter {
    position: absolute;
    top: 65%;
    left: 0;
    color: rgba(0, 0, 0, 0.5);
    font-size: 1.2rem;
    font-weight: 600;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;

    &--percentage {
      color: #000;
    }

    @media (max-width: 600px) {
      font-size: 1rem;
    }
  }

  &__width_animation {
    animation: width_animation 1s forwards;

    @keyframes width_animation {
      0% {
        width: 85%;
      }
      100% {
        width: 16rem;
      }
    }
  }

  &__svg {
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 85%;
    opacity: 0;

    @media (max-width: 600px) {
      width: 20rem;
    }
  }
}
