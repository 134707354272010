@import "../../index.scss";

.container {
  display: flex;
  flex-direction: column;
  background-image: url("../images/profile/bg1.png");
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 10;
}

.profile {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;
  padding: 0 3rem 3rem 3rem;

  &__details {
    width: 67%;

    &--socials {
      font-size: 1.5rem;
      display: flex;
      gap: 1rem;

      a:hover {
        transform: scale3d(1.5, 1.5, 1.5);
      }

      @media (max-width: 500px) {
        margin-bottom: 2rem;
        font-size: 1.2rem;
      }
    }

    &--name {
      font-size: clamp-calc(1900px, 300px, 50px, 27px);
      font-weight: 600;

      &--highlighted {
        font-family: "Poppins";
        color: var(--dark-orange);
        font-size: clamp-calc(1900px, 300px, 55px, 27px);
      }
    }

    &--roles {
      display: flex;
      flex-direction: column;
      margin: 1rem 0 2rem 0;
      font-size: clamp-calc(1900px, 300px, 28px, 20px);
      font-weight: 500;

      &--animation {
        font-family: "Poppins";
        font-weight: 400;
        font-size: clamp-calc(1900px, 300px, 35px, 22px);
        font-style: italic;
        margin-top: 1rem;
      }

      &--tagline {
        font-size: clamp-calc(1900px, 300px, 32px, 19px);
        margin-top: 2rem;
        font-family: "Poppins";
      }

      @media (max-width: 500px) {
        display: none;
      }
    }

    &--buttons {
      display: flex;
      gap: 2rem;

      @media (max-width: 700px) {
        gap: 1rem;
      }

      @media (max-width: 500px) {
        margin-top: 13rem;
      }
    }

    @media (max-width: 1100px) {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }

  &__picture {
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 0 0 var(--black);
    border-radius: 50%;
    height: 22rem;
    width: 22rem;

    &--bg {
      height: 92%;
      width: 92%;
      background-image: url("../images//profile/profile.jpg");
      background-size: cover;
      border-radius: 50%;
      background-repeat: no-repeat;

      &:hover {
        transform: scale3d(1.07, 1.07, 1.07);
        transition: 1s ease-out;
      }
    }

    @media (max-width: 1300px) {
      height: 18rem;
      width: 18rem;
    }

    @media (max-width: 1200px) {
      height: 15rem;
      width: 15rem;
    }

    @media (max-width: 1100px) {
      display: none;
    }
  }

  @media (max-width: 1400px) {
    gap: 4rem;
  }

  @media (max-width: 1300px) {
    gap: 2rem;
  }

  @media (max-width: 1200px) {
    gap: 0rem;
  }

  @media (max-width: 400px) {
    padding: 1rem;
  }
}
